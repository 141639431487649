import { backendAddress } from '../common/constants';
import { handleResponse, authenticate } from './common';
import { getExternalClientDB } from '../context/AuthContext';

export async function getSelf(id) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  let response = {};
  try {
    response = await fetch(backendAddress + `user?user=${id}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        jwt: userInfo?.signInUserSession.accessToken.jwtToken ?? '',
        forceDB: getExternalClientDB()
      }
    });
  } catch (err) {
    console.error(err);
    response = null;
  }

  return await handleResponse(response);
}

export async function getUsers() {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(backendAddress + 'user/all', {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo?.signInUserSession.accessToken.jwtToken ?? '',
      forceDB: getExternalClientDB()
    }
  });
  return await handleResponse(response);
}

// export async function deleteUser(_id) {
//   const userInfo = await authenticate();
//   if (!userInfo) {
//     return null;
//   }

//   const response = await fetch(backendAddress + 'users/' + _id, {
//     method: 'DELETE',
//     headers: {
//       Accept: 'application/json',
//       'Content-Type': 'application/json',
//       jwt: userInfo?.signInUserSession.accessToken.jwtToken ?? ''
//     }
//   });
//   return await handleResponse(response);
// }

export async function deleteUser(email) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }
  const userData = {
    username: email
  };

  const response = await fetch(backendAddress + 'user/delete', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo?.signInUserSession.accessToken.jwtToken ?? '',
      forceDB: getExternalClientDB()
    },
    body: JSON.stringify(userData)
  });
  return await handleResponse(response);
}

export async function postUser(userData) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  // const userData = {
  //   username:'fppimenta@hotmail.com',
  //   password:'Aojwjvo76'
  // };

  const response = await fetch(backendAddress + 'user/create', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo?.signInUserSession.accessToken.jwtToken ?? '',
      forceDB: getExternalClientDB()
    },
    body: JSON.stringify(userData)
  });
  return await handleResponse(response);
}

export async function putUser(userData) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(backendAddress + 'users/' + userData.cognitoID, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo?.signInUserSession.accessToken.jwtToken ?? '',
      forceDB: getExternalClientDB()
    },
    body: JSON.stringify(userData)
  });
  return await handleResponse(response);
}
