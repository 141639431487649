import { ArrowLeftCircleIcon } from '@heroicons/react/24/outline';
import { toast } from 'react-toastify';
import { sort } from 'fast-sort';
import groupBy from 'lodash/groupBy';
import moment from 'moment';
import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import TransitionCollapse from '../../components/TransitionCollapse';
import AppContext from '../../context/AppContextBase';
import { getIvas, getTaxes } from '../../network/clients';
import Loading from '../../components/Loading';

const SingleClient = () => {
  const context = React.useContext(AppContext);
  const params = useParams();
  const clientNif = params?.clientNif;

  const [client, setClient] = React.useState(null);
  const [ivas, setIvas] = React.useState(null);
  const [taxes, setTaxes] = React.useState(null);
  const [statistics, setStatistics] = React.useState(null);

  useEffect(() => {
    const targetClient = context?.clients?.find(
      (client) => client?.nif === clientNif
    );

    if (!targetClient) {
      toast.error(
        'Client not found. Please try again or ensure the client exists in the platform.'
      );
    } else {
      setClient(targetClient);
    }

    console.log({ targetClient });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const ivaPromise = getIvas(clientNif);
    const taxPromise = getTaxes(clientNif);

    ivaPromise.then((response) => {
      if (!response?.ok || !response?.data) {
        console.error('Could not get IVAs for client ' + clientNif);
        toast.error('Could not get IVAs for client ' + clientNif);
      }
      setIvas(response.data);
    });

    taxPromise.then((response) => {
      if (!response?.ok || !response?.data) {
        console.error('Could not get Taxes for client ' + clientNif);
        toast.error('Could not get Taxes for client ' + clientNif);
      }
      setTaxes(response.data);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!taxes && !ivas) {
      return;
    }

    const newStatistics = {};
    newStatistics['IVA'] = ivas?.length;
    const distinct = groupBy(taxes ?? [], 'taxType');
    for (const key of Object.keys(distinct ?? {})) {
      newStatistics[key] = distinct[key].length;
    }

    setStatistics(newStatistics);
  }, [ivas, taxes]);

  const header = (
    <div className="w-full h-auto flex items-center pb-4 mb-4 border-b border-gray-200">
      <Link to={`/app/clients`} className="">
        <ArrowLeftCircleIcon className="stroke-1 stroke-current h-12 w-12 mr-4 rounded-full bg-transparent hover:bg-blue-500 text-gray-800 hover:text-white transition"></ArrowLeftCircleIcon>
      </Link>

      <div className="w-full h-full flex items-baseline whitespace-nowrap">
        <div className="font-medium text-5xl text-gray-600 pb-1 pr-4">
          {client?.name}
        </div>
        <div className="font-light text-3xl text-gray-600 pb-1">
          {client?.description}
        </div>
      </div>
    </div>
  );

  return (
    <div className="w-full min-h-full pb-8 flex flex-col">
      {header}
      <TransitionCollapse
        header={
          <div className="font-bold text-2xl leading-none pr-1 overflow-y-hidden flex items-baseline">
            Statistics
            <div className="pl-2 font-normal text-gray-500 group-hover:text-white text-lg leading-none pr-1 overflow-y-hidden">
              Number of entries for each tax type
            </div>
          </div>
        }
        className="w-full h-auto mb-4"
        contentClassName="p-2 font-medium border-b border-l border-r border-gray-200"
        headerClassName="h-20 px-4 rounded-t-md bg-gray-50 border border-gray-200 transition group bg-transparent hover:bg-blue-500 text-gray-800 hover:text-white transition"
      >
        <div className="flex flex-wrap">
          {!statistics ? (
            <Loading className="text-blue-400 w-12 h-12"></Loading>
          ) : (
            Object.keys(statistics ?? {})?.map((key, index) => {
              const stat = statistics[key];
              return (
                <div
                  key={index}
                  className={`relative w-1/6 rounded-md bg-blue-200 flex justify-between items-baseline px-4 py-2 mx-2 my-2 font-medium`}
                >
                  <div className="text-xl pr-2">{key} </div>
                  <div className="text-lg pl-2">{stat} </div>
                </div>
              );
            })
          )}
        </div>
      </TransitionCollapse>
      <TransitionCollapse
        header={
          <div className="font-bold text-2xl leading-none pr-1 overflow-y-hidden flex items-baseline">
            Data Collection
            <div className="pl-2 font-normal text-gray-500 group-hover:text-white text-lg leading-none pr-1 overflow-y-hidden">
              Robot
            </div>
          </div>
        }
        className="w-full h-auto mb-4"
        contentClassName="flex flex-col font-medium min-h-24 border-b border-l border-r border-gray-200"
        headerClassName="h-20 px-4 rounded-t-md bg-gray-50 border border-gray-200 group transition bg-transparent hover:bg-blue-500 text-gray-800 hover:text-white transition"
      >
        <div
          className={`relative w-full flex items-center border-b border-gray-100 bg-blue-100 `}
        >
          <div className="w-1/12 p-4 h-full">N</div>
          <div className="flex items-center w-1/6 h-full text-xl p-4">Date</div>
          <div className="flex items-center w-1/6 h-full text-xl p-4">
            Status
          </div>
          <div className="flex items-center w-1/6 h-full text-xl p-4">
            Message
          </div>
        </div>
        {!!client?.scrapes &&
          sort(client.scrapes)
            .desc((sc) => moment(sc?.date).format('YYYYMMDDHH:mm'))
            ?.map((scrape, index) => {
              const isEven = index % 2 === 0;
              return (
                <div
                  key={index}
                  className={`relative w-full flex items-center border-b border-gray-100 hover:bg-blue-50 ${
                    isEven ? 'bg-white' : 'bg-gray-50'
                  }`}
                >
                  <div className="w-1/12 p-4 h-full">{index}</div>
                  <div className="flex items-center w-1/6 h-full text-xl p-4">
                    {scrape?.date
                      ? moment(scrape?.date).format('DD MMM YYYY - HH:mm')
                      : '?'}
                  </div>
                  <div className="flex items-center w-1/6 min-h-full text-xl p-4">
                    {scrape?.isError ? 'Fail' : 'Sucess'}
                  </div>
                  <pre className="flex items-center w-1/6 flex-1 min-h-full whitespace-normal break-words overflow-x-scroll font-thin text-xl p-4">
                    {scrape?.message ?? '-'}
                  </pre>
                </div>
              );
            })}
      </TransitionCollapse>
      <TransitionCollapse
        header={
          <div className="font-bold text-2xl leading-none pr-1 overflow-y-hidden flex items-baseline">
            IVA
          </div>
        }
        className="w-full h-auto mb-4"
        contentClassName="flex font-medium flex-col min-h-24 border-b border-l border-r border-gray-200"
        headerClassName="h-20 px-4 rounded-t-md bg-gray-50 border border-gray-200 group transition bg-transparent hover:bg-blue-500 text-gray-800 hover:text-white transition"
      >
        <div
          className={`relative w-full flex items-center border-b border-gray-100 bg-blue-100 `}
        >
          <div className="w-1/13 p-2 h-full overflow-x-scroll">ID</div>
          <div className="w-1/13 p-2 h-full overflow-x-scroll">NIF</div>
          <div className="w-1/13 p-2 h-full overflow-x-scroll">Ano</div>
          <div className="w-1/13 p-2 h-full overflow-x-scroll">Período</div>
          <div className="w-1/13 p-2 h-full overflow-x-scroll">Tipo</div>
          <div className="w-1/13 p-2 h-full overflow-x-scroll">TaxType</div>
          <div className="w-1/13 p-2 h-full overflow-x-scroll">
            Base Tribut.
          </div>
          <div className="w-1/13 p-2 h-full overflow-x-scroll">
            Imp. Liquid.
          </div>
          <div className="w-1/13 p-2 h-full overflow-x-scroll">
            Imp. Dedutív.
          </div>
          <div className="w-1/13 p-2 h-full overflow-x-scroll">
            Imp. a Receber
          </div>
          <div className="w-1/13 p-2 h-full overflow-x-scroll">
            Valor a Receber
          </div>
          <div className="w-1/13 p-2 h-full overflow-x-scroll">
            Imp. a Entregar
          </div>
          <div className="w-1/13 p-2 h-full overflow-x-scroll">
            Valor a Entregar
          </div>
        </div>
        {!ivas ? (
          <Loading className="text-blue-400 w-12 h-12"></Loading>
        ) : (
          ivas?.map((iva, index) => {
            const isEven = index % 2 === 0;
            return (
              <div
                key={index}
                className={`relative w-full flex items-center border-b border-gray-100 hover:bg-blue-50 ${
                  isEven ? 'bg-white' : 'bg-gray-50'
                }`}
              >
                <div className="w-1/13 p-2 h-full overflow-x-scroll">
                  {iva.id}
                </div>
                <div className="w-1/13 p-2 h-full overflow-x-scroll">
                  {iva.nif}
                </div>
                <div className="w-1/13 p-2 h-full overflow-x-scroll">
                  {iva.ano}
                </div>
                <div className="w-1/13 p-2 h-full overflow-x-scroll">
                  {iva.periodo}
                </div>
                <div className="w-1/13 p-2 h-full overflow-x-scroll">
                  {iva.tipo}
                </div>
                <div className="w-1/13 p-2 h-full overflow-x-scroll">
                  {iva.taxType}
                </div>
                <div className="w-1/13 p-2 h-full overflow-x-scroll">
                  {iva.base_tributavel}
                </div>
                <div className="w-1/13 p-2 h-full overflow-x-scroll">
                  {iva.imposto_liquidado}
                </div>
                <div className="w-1/13 p-2 h-full overflow-x-scroll">
                  {iva.imposto_dedutivel}
                </div>
                <div className="w-1/13 p-2 h-full overflow-x-scroll">
                  {iva.imposto_a_receber}
                </div>
                <div className="w-1/13 p-2 h-full overflow-x-scroll">
                  {iva.valor_a_receber}
                </div>
                <div className="w-1/13 p-2 h-full overflow-x-scroll">
                  {iva.imposto_a_entregar}
                </div>
                <div className="w-1/13 p-2 h-full overflow-x-scroll">
                  {iva.valor_a_entregar}
                </div>
              </div>
            );
          })
        )}
      </TransitionCollapse>
      <TransitionCollapse
        header={
          <div className="font-bold text-2xl leading-none pr-1 overflow-y-hidden flex items-baseline">
            Taxes
          </div>
        }
        className="w-full h-auto mb-4"
        contentClassName="flex flex-col font-medium min-h-24 border-b border-l border-r border-gray-200"
        headerClassName="h-20 px-4 rounded-t-md bg-gray-50 border border-gray-200 group transition bg-transparent hover:bg-blue-500 text-gray-800 hover:text-white transition"
      >
        <div
          className={`relative w-full flex items-center border-b border-gray-100 bg-blue-100 `}
        >
          <div className="w-1/7 p-2 h-full overflow-x-scroll">ID</div>
          <div className="w-1/7 p-2 h-full overflow-x-scroll">NIF</div>
          <div className="w-1/7 p-2 h-full overflow-x-scroll">Descrição</div>
          <div className="w-1/7 p-2 h-full overflow-x-scroll">TaxType</div>
          <div className="w-1/7 p-2 h-full overflow-x-scroll">Período</div>
          <div className="w-1/7 p-2 h-full overflow-x-scroll">Valor</div>
          <div className="w-1/7 p-2 h-full overflow-x-scroll">Valor Pago</div>
        </div>
        {!taxes ? (
          <Loading className="text-blue-400 w-12 h-12"></Loading>
        ) : (
          taxes?.map((tax, index) => {
            const isEven = index % 2 === 0;
            return (
              <div
                key={index}
                className={`relative w-full flex items-center border-b border-gray-100 hover:bg-blue-50 ${
                  isEven ? 'bg-white' : 'bg-gray-50'
                }`}
              >
                <div className="w-1/7 p-2 h-full overflow-x-scroll">
                  {tax.id}
                </div>
                <div className="w-1/7 p-2 h-full overflow-x-scroll">
                  {tax.nif}
                </div>
                <div className="w-1/7 p-2 h-full overflow-x-scroll">
                  {tax.description}
                </div>
                <div className="w-1/7 p-2 h-full overflow-x-scroll">
                  {tax.taxType}
                </div>
                <div className="w-1/7 p-2 h-full overflow-x-scroll">
                  {tax.period}
                </div>
                <div className="w-1/7 p-2 h-full overflow-x-scroll">
                  {tax.value}
                </div>
                <div className="w-1/7 p-2 h-full overflow-x-scroll">
                  {tax.valuePaid}
                </div>
              </div>
            );
          })
        )}
      </TransitionCollapse>
    </div>
  );
};

export default SingleClient;
