import React from 'react';
import { Outlet } from 'react-router-dom';

import MenuTop from '../../components/MenuTop/MenuTop';

// const routes = [
//   {
//     label: 'Explore',
//     subRoutes: [
//       { label: 'Explore Network', href: '/app/explore' },
//       { label: 'Issue Scanner', href: '/app/issuescanner' },
//       { label: 'Line Scanner ', href: '/app/issueslineoverview' }
//     ]
//   },
//   {
//     label: 'Optimize',
//     href: '/app/optimize',
//     subRoutes: []
//   },
//   // {
//   //   label: 'Optimize',
//   //   subRoutes: [
//   //     { label: 'Optimize Network', href: '/app/optimize' },
//   //     { label: 'Prognosis', href: '/app/prognosis' }
//   //   ]
//   // },
//   {
//     label: 'Plan',
//     href: null,
//     subRoutes: []
//   }
// ];

const AdminHome = () => {
  return (
    <div
      id="admin"
      className="relative flex flex-col justify-start items-center w-full h-full min-h-screen bg-white"
    >
      {/* <div className="w-full">
        <MenuAdmin className="fixed z-100" routes={routes}></MenuAdmin>
      </div> */}

      <div className="w-full flex-grow flex items-start justify-start">
        <div className="w-full fixed z-20 shadow-md">
          <MenuTop className="w-full h-18"></MenuTop>
        </div>

        <div className="w-full self-stretch flex-grow flex items-start justify-start pt-24 p-8">
          <Outlet></Outlet>
        </div>
      </div>
    </div>
  );
};

export default AdminHome;
