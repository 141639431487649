import React from 'react';
import AppContext from './AppContextBase';
import { getAllClients } from '../network/clients';
import { toast } from 'react-toastify';

const AppContextProvider = ({ children }) => {
  const [clients, setClients] = React.useState(null);

  const getClients = async () => {
    // Não apagar, vou usar isto mais tarde, com o BE:

    const response = await getAllClients();
    if (!response?.ok) {
      toast.error('Erro a carregar informação. Por favor tente mais tarde.');
      return;
    } else {
      const clients = response?.data ?? [];

      setClients(clients);
    }

    // const clients = {
    //   500409838: {
    //     name: 'SBA',
    //     description: 'Silva e Barbosa Associados',
    //     nif: '500409838',
    //     password: 'dummy',
    //     contact: 'luis.barbosa@sba.pt',
    //     taxes: dummies.taxData,
    //     ivas: dummies.ivaData,
    //     scrapes: dummies.scrapes
    //   }
    // };
  };

  React.useEffect(() => {
    getClients();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <AppContext.Provider
      value={{
        clients,
        getClients
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

const AppContextConsumer = AppContext.Consumer;

export { AppContextProvider, AppContextConsumer };
