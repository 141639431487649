import { Navigate } from 'react-router-dom';
// import {
//   ChartPieIcon,
//   SparklesIcon,
//   ViewGridIcon
// } from '@heroicons/react/outline';

import Login from './pagesPublic/AuthLogin';
import Recover from './pagesPublic/AuthRecover';
//import Register from './pagesPublic/AuthRegister';
import OutletHome from './pagesPublic/OutletHome';
import Lost from './pagesPublic/Lost';

import OutletAdminHome from './pagesAdmin/AdminHome';
import PageClients from './pagesAdmin/Clients';
import PageSingleClient from './pagesAdmin/SingleClient';

// import OutletSettings from  './pagesAdmin/Settings';
// import UserManagement from './pagesAdmin/Settings/UserManagement';
// import OutletSettings from './pagesAdmin/Settings';
// import GlobalSettings from './pagesAdmin/Settings/GlobalSettings';
import LandingPage from './pagesPublic/LandingPage';

// Important stuff about auth and protecting routes: https://stackoverflow.com/a/64347082
const routes = (isLoggedIn, location, permissions) => {
  return [
    {
      path: '/*',
      noMenu: true,
      element: <OutletHome></OutletHome>,
      label: 'Home',
      children: [
        {
          path: '',
          label: 'Landing Page',
          element: <LandingPage />
        },
        {
          path: 'login',
          label: 'Login',
          element: isLoggedIn ? (
            <Navigate to={location?.state?.from ?? '/app'} />
          ) : (
            <Login />
          )
        },
        {
          path: 'recover',
          label: 'Recover Password',
          element: <Recover></Recover>
        },
        {
          path: '*',
          element: isLoggedIn ? (
            <Navigate to={location?.state?.from ?? '/app/dashboard'} />
          ) : (
            <Navigate to="login" state={{ from: location?.pathname }} />
          )
        }
      ]
    },
    {
      path: '/app/*',
      element: isLoggedIn ? (
        <OutletAdminHome />
      ) : (
        <Navigate state={{ from: location?.pathname }} to="/login" />
      ),
      children: [
        { path: 'clients', element: <PageClients /> },
        { path: 'client/:clientNif', element: <PageSingleClient /> },
        // {
        //   path: 'settings',
        //   element: <PageSettings />
        //     ,children: [
        //       {
        //         path: 'global',
        //         label: 'Global Settings',
        //         element: <GlobalSettings></GlobalSettings>
        //       },
        //       {
        //         path: 'users',
        //         label: 'User Management',
        //         element: permissions?.seeUsers ? (
        //           <UserManagement></UserManagement>
        //         ) : (
        //           <></>
        //         )
        //       }
        //     ]
        // },

        { path: '*', element: <Navigate to="/app/clients" /> }
      ]
    },
    { path: '*', element: <Lost></Lost> }
  ];
};

export default routes;
