import { Popover, Transition } from '@headlessui/react';
import {
  ChevronDownIcon,
  ArrowLeftOnRectangleIcon,
  UserCircleIcon,
  XMarkIcon
} from '@heroicons/react/24/outline';
import React, { Fragment } from 'react';
import { toast } from 'react-toastify';
import AuthContext from '../../context/AuthContextBase';
import ChangePasswordForm from './ChangePasswordForm';

export default function PopoverProfile({ className = '' }) {
  const [showChangePassword, setShowChangePassword] = React.useState(false);
  const authContext = React.useContext(AuthContext);

  const { userBE } = authContext;
  return (
    <div
      className={`${className} border border-gray-400 rounded-full flex items-center transition hover:bg-blue-200 hover:text-white hover:border-transparent`}
    >
      <Popover className="">
        {({ open }) => (
          <>
            <Popover.Button
              className={`group flex items-center px-4 py-2 text-base font-medium rounded-xl cursor-pointer`}
            >
              <UserCircleIcon className=" stroke-1 mr-2 h-8 w-8 "></UserCircleIcon>
              <div className="whitespace-nowrap">{userBE?.name}</div>
              <ChevronDownIcon className="pl-2 h-6 w-6"></ChevronDownIcon>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute right-0 z-10 w-96 -translate-x-2 px-4 mt-4 transform sm:px-0 lg:max-w-3xl">
                <Popover.Button
                  className={`absolute right-0 top-0 z-50 text-base text-gray-800 p-2 hover:scale-125 transform transition rounded-md`}
                >
                  <XMarkIcon className="stroke-2 stroke-current w-6 h-6"></XMarkIcon>
                </Popover.Button>
                <div className="overflow-hidden rounded-xl shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className="relative flex justify-between p-8 bg-white">
                    <div className="flex-1 flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50">
                      <div className="flex items-center justify-center flex-shrink-0 w-10 h-10 text-gray-800 sm:h-12 sm:w-12">
                        <UserCircleIcon
                          className=" stroke-1 mr-2 "
                          aria-hidden="true"
                        />
                      </div>
                      <div className="ml-4">
                        <p className="text-sm font-medium text-gray-900">
                          {userBE?.name ?? ''}
                        </p>
                        <p className="text-sm text-gray-500">
                          {userBE?.email ?? ''}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`p-8 py-4 w-full bg-gray-50 flex ${
                      showChangePassword
                        ? 'flex-col items-start'
                        : 'flex-row items-center'
                    }  justify-between`}
                  >
                    <div className=" pr-2 py-2  rounded-md">
                      <div className="flex items-center">
                        <button
                          onClick={() =>
                            setShowChangePassword(!showChangePassword)
                          }
                          className={`text-gray-900 h-12 border border-gray-900 bg-white px-3 py-2 rounded-md inline-flex justify-center items-center text-sm font-medium hover:bg-gray-800 hover:text-white hover:border-white`}
                        >
                          {showChangePassword ? 'Cancelar' : 'Alterar Password'}
                        </button>
                      </div>
                    </div>
                    <div className="flex items-center">
                      {!!showChangePassword && (
                        <ChangePasswordForm
                          onSuccess={() => {
                            setShowChangePassword(false);
                            toast.success('Password alterada com sucesso');
                          }}
                          onFailure={(errorMessage) => {
                            toast.error(errorMessage);
                          }}
                        ></ChangePasswordForm>
                      )}
                    </div>
                    <div
                      className="flex-1 flex justify-center items-center text-gray-900 h-12 border border-gray-900 bg-white px-3 py-2 rounded-md text-sm font-medium hover:text-white hover:border-white w-auto cursor-pointer bg-opacity-20 hover:bg-red-800 hover:bg-opacity-100"
                      title="Log out"
                      onClick={() => {
                        authContext?.logOut();
                      }}
                    >
                      <ArrowLeftOnRectangleIcon className="h-full stroke-1 pr-2"></ArrowLeftOnRectangleIcon>
                      <span className="select-none">Log out</span>
                    </div>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
}
