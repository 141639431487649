const awsConfig = {
  aws_app_analytics: 'disabled',
  aws_cognito_identity_pool_id: process.env.REACT_APP_AWS_IDENTITY_POOL_ID,
  aws_cognito_region: process.env.REACT_APP_AWS_REGION,
  aws_project_region: process.env.REACT_APP_AWS_REGION,
  aws_resource_name_prefix: '',
  aws_sign_in_enabled: 'enable',
  aws_user_pools: 'enable',
  aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOL_ID,
  aws_user_pools_web_client_id:
    process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID
};

export default awsConfig;
