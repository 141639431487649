import React from 'react';
import { Collapse } from 'react-collapse';
import ls from 'local-storage';
import isNil from 'lodash/isNil';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import './TransitionCollapse.scss';

const TransitionCollapse = ({
  header,
  children,
  className = '',
  isOpen: isOpenProp,
  setOpen: setOpenProp,
  localStorageKey,
  contentClassName = '',
  headerClassName = ''
}) => {
  const [isOpenState, setOpenState] = React.useState(isOpenProp ?? true);

  const isControlled = !isNil(isOpenProp) && !isNil(setOpenProp);

  const [isOpen, setOpen] = isControlled
    ? [isOpenProp, setOpenProp]
    : [isOpenState, setOpenState];

  React.useEffect(() => {
    /* On mount: get value from local storage and set state (or context) */

    if (!localStorageKey) {
      return;
    }

    const lsValue = ls.get(localStorageKey);
    if (lsValue === 'open') {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    /* On change: is local storage key is set, save new value to LS */

    if (!localStorageKey) {
      return;
    }
    ls.set(localStorageKey, isOpen ? 'open' : null);
  }, [isOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={'box-border h-auto flex flex-col ' + className}>
      <div
        onClick={() => {
          setOpen(!isOpen);
        }}
        className={`box-border relative w-auto cursor-pointer flex justify-between items-center break-normal select-none ${headerClassName}`}
      >
        {header}
        {isOpen ? (
          <ChevronUpIcon className="stroke-2 stroke-current h-3/4 max-h-12 ml-2"></ChevronUpIcon>
        ) : (
          <ChevronDownIcon className=" stroke-2 stroke-current h-3/4 max-h-12 ml-2"></ChevronDownIcon>
        )}
      </div>
      <Collapse isOpened={isOpen}>
        <div
          className={`box-border w-full h-full break-normal flex-1 overflow-visible ${contentClassName}`}
        >
          {children}
        </div>
      </Collapse>
    </div>
  );
};

export default TransitionCollapse;
