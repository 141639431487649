import React from 'react';
import { ArrowPathIcon } from '@heroicons/react/24/outline';

const Loading = ({ className }) => {
  return (
    <div className={`relative ${className}}`}>
      <ArrowPathIcon className="w-full h-full stroke-1 stroke-current animate-spin"></ArrowPathIcon>
    </div>
  );
};

export default Loading;
