import React from 'react';
import PopoverNotification from '../PopoverNotification';

import PopoverProfile from '../PopoverProfile';

export default function MenuTop({ routes, className = '' }) {
  return (
    <div
      className={` w-full py-2 px-8 flex-1 flex justify-between bg-white items-stretch overflow-y-visible ${className}`}
    >
      <div className="flex justify-start items-center text-4xl text-blue-800 font-medium">
        Finpartner Validator
      </div>
      <div className="flex justify-end items-center">
        <PopoverProfile className="mr-2"></PopoverProfile>
        <PopoverNotification className=""></PopoverNotification>
      </div>
    </div>
  );
}
