import React from 'react';
// @ts-ignore
import { useRoutes, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import AuthContext from './context/AuthContextBase';
import routes from './routes';
import MetaTags from './components/MetaTags';

import './App.scss';
import '@fontsource/source-sans-pro';
import { Auth } from 'aws-amplify'; 
import awsConfig from './context/awsConfig';
import logo from './img/logo_small.svg';

function App() {
  const authContext = React.useContext(AuthContext);
  const location = useLocation();

  const appRoutes = useRoutes(
    routes(authContext.authenticated, location, authContext.permissions)
  );

  Auth.configure(awsConfig);

  return (
    <>
      <MetaTags
        title={'Finpartner AT Validator'}
        description={'Finpartner AT Validator'}
        imageSrc={logo}
        url={window.location.href}
      ></MetaTags>

      {/* Maybe change to justify-start when developing a new app: */}
        <AppLoading 
        isLoading={false}
        // isLoading={!authContext.init}
        >{appRoutes}</AppLoading>

      <ToastContainer
        position="top-right"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

export default App;

const AppLoading = ({ isLoading, children }) => {
  return (
    <>
      {isLoading ? <div className={'loader'}></div> : null}
      {children}
    </>
  );
};
