import { Popover, Transition } from '@headlessui/react';
import { BellIcon, XMarkIcon } from '@heroicons/react/24/outline';
import React, { Fragment } from 'react';

export default function PopoverNotification({ className = '' }) {
  return (
    <div className={`${className} flex items-center`}>
      <Popover className="">
        {({ open }) => (
          <>
            <Popover.Button
              className={`flex items-center px-2 py-2 text-base font-medium rounded-full cursor-pointer bg-white hover:bg-blue-200 hover:text-white transition`}
            >
              <BellIcon className=" stroke-1 h-8 w-8 "></BellIcon>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute right-0 z-10 w-96 -translate-x-2 px-4 mt-4 transform sm:px-0 lg:max-w-3xl">
                <Popover.Button
                  className={`absolute right-0 top-0 z-50 text-base text-gray-800 p-2 hover:scale-125 transform transition rounded-md`}
                >
                  <XMarkIcon className="stroke-2 stroke-current w-6 h-6"></XMarkIcon>
                </Popover.Button>
                <div className="overflow-hidden rounded-md shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className="relative flex justify-between p-8 bg-white">
                    <div className="flex-1 flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50">
                      <div className="flex items-center justify-center flex-shrink-0 w-10 h-10 text-gray-800 sm:h-12 sm:w-12">
                        <BellIcon
                          className=" stroke-1 mr-2 "
                          aria-hidden="true"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
}
