import React from 'react';
import AuthContext from './AuthContextBase';
import { getSelf } from '../network/users';
import awsExports from './awsConfig';
import { Auth } from 'aws-amplify';

Auth.configure(awsExports);

const AuthContextProvider = ({ children }) => {
  const [self, setSelf] = React.useState(null);
  const [authenticated, setAuthenticated] = React.useState(false);
  const [userBE, setUserBE] = React.useState(null);
  const [permissions, setPermissions] = React.useState(null);

  const authInit = async () => {
    const cognitoUserSession = await authenticator();

    console.log('authenticated in cognito', cognitoUserSession);

    if (cognitoUserSession !== null) {
      const cognitoUser = {
        name:
          cognitoUserSession?.attributes?.given_name +
          ' ' +
          cognitoUserSession?.attributes?.family_name,
        email: cognitoUserSession?.attributes?.email,
        username: cognitoUserSession?.attributes?.email,
        cognitoId: cognitoUserSession?.attributes?.sub
      };

      const userBeResponse = await getSelf(
        cognitoUserSession?.attributes?.email
      );
      console.log('User in BE', userBeResponse.data);

      setAuthenticated(true);
      setSelf(cognitoUser);
      setUserBE(userBeResponse?.data);
      setPermissions(userBeResponse?.data?.permissions);
    }
  };

  React.useEffect(() => {
    authInit();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const authenticator = async () => {
    let userInfo;
    try {
      userInfo = await Auth.currentAuthenticatedUser({
        bypassCache: process.env.NODE_ENV === 'development'
      });
      if (process.env.NODE_ENV === 'development') {
        console.log('dev info', userInfo);
      }
    } catch (err) {
      console.error('authentication error: ', err);
      return null;
    }
    //console.log('Auth - User Info', userInfo?.signInUserSession.accessToken.jwtToken ?? '');
    return userInfo;
  };

  const logIn = async (email, password) => {
    let logInResponse;
    logInResponse = await Auth.signIn(email, password);
    const user = {
      name:
        logInResponse?.attributes?.given_name +
        ' ' +
        logInResponse?.attributes?.family_name,
      username: logInResponse?.attributes?.email,
      cognitoId: logInResponse?.attributes?.sub
    };

    if (logInResponse && !logInResponse.challengeName) {
      setAuthenticated(true);
      setSelf(user);
      return logInResponse;
    } else if (logInResponse) {
      return logInResponse;
    } else {
      console.log(logInResponse);
      throw new Error(logInResponse);
    }
  };

  const logOut = async () => {
    try {
      await Auth.signOut();

      setAuthenticated(false);
      setSelf(null);
    } catch (error) {
      console.log('error signing out: ', error);
    }
  };

  const changePassword = async (cognitoUser, currentPassword, newPassword) => {
    let currentUser;
    console.log(cognitoUser);
    try {
      currentUser = await Auth.currentAuthenticatedUser();
      if (!currentUser) {
        throw new Error();
      }
    } catch (e) {
      currentUser = cognitoUser;
    }

    return await Auth.changePassword(
      currentUser,
      currentPassword,
      newPassword
      // currentSession
    );
  };

  const forgotPassword = (username) => {
    return Auth.forgotPassword(username)
      .then((data) => console.log(data))
      .catch((err) => console.log(err));
  };

  const completeForgotPassword = (username, securityCode, newPassword) => {
    const authResult = Auth.forgotPasswordSubmit(
      username,
      securityCode,
      newPassword
    )
      .then((data) => console.log(data))
      .catch((err) => console.log(err));

    return authResult;
  };

  const forceChangePassword = async (
    cognitoUser,
    newPassword,
    requiredAttributes = {}
  ) => {
    console.log(cognitoUser, requiredAttributes);
    return await Auth.completeNewPassword(
      cognitoUser,
      newPassword,
      requiredAttributes
    );
  };

  return (
    <AuthContext.Provider
      value={{
        authenticated,
        // https://stackoverflow.com/a/51200448 :
        ...(process.env.NODE_ENV === 'development' && {
          setAuthenticated,
          setSelf,
          setUserBE
        }),

        logOut,
        logIn,
        self,
        userBE,
        permissions,
        changePassword,
        forgotPassword,
        forceChangePassword,
        completeForgotPassword
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

let externalClientDB = null;

export const getExternalClientDB = () => {
  return externalClientDB;
};

/**
 * @deprecated Avoid using this one. AuthContext has an effect to update externalClientDB automatically when state changes
 */
export const setExternalClientDB = (value) => {
  externalClientDB = value;
};

const AuthContextConsumer = AuthContext.Consumer;

export { AuthContextProvider, AuthContextConsumer };
