import React from 'react';
import { Outlet } from 'react-router-dom';

import './Home.scss';

const OutletHome = () => {
  return (
    <div
      id="home"
      className="flex justify-center items-center w-full h-full bg-white"
    >
      <Outlet></Outlet>
    </div>
  );
};

export default OutletHome;
