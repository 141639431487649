import React from 'react';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as Logo } from '../../img/logo_small.svg';

import './Home.scss';

const LandingPage = () => {
  const navigate = useNavigate();

  React.useEffect(() => {
    setTimeout(() => {
      navigate('/login');
    }, 1000);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="flex justify-center items-center w-full h-full bg-fixed bg-center bg-no-repeat bg-cover">
      <div className="w-1/4 md:w-1/6 xl:w-1/8 h-auto animate-pulse">
        <Logo className="w-full h-auto"></Logo>
      </div>
    </div>
  );
};

export default LandingPage;
