import React, { useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { ReactComponent as Logo } from '../../img/logo_small.svg';
import isEmail from 'validator/lib/isEmail';

import AuthContext from '../../context/AuthContextBase';

const MODES = { LOGIN: 'login', RENEW: 'renew' };

const Login = () => {
  const [loading, setLoading] = React.useState(false);
  const [cognitoUser, setCognitoUser] = React.useState(null);
  const [currentPassword, setCurrentPassword] = React.useState(null);
  const [mode, setMode] = React.useState(MODES.LOGIN);
  const authContext = useContext(AuthContext);

  const navigate = useNavigate();
  const location = useLocation();
  const fromRoute = location?.state?.from;

  // Did mount
  React.useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const user = authContext.self;

      console.log(
        `In Login, got user from context ${user?.name} and fromRoute: ${fromRoute}`
      );
      if (user) {
        setLoading(false);
        if (fromRoute) {
          console.log('Login: redirecting to original route: ', fromRoute);
          navigate(fromRoute);
        } else {
          console.log('Login: redirecting to default /app');
          navigate('/app');
        }
        return;
      }
      setLoading(false);
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const errorOut = async (e) => {
    await authContext.logOut();

    if (e.message.includes('User is not confirmed.')) {
      toast.error('Please confirm your account with the email we sent you');
    } else if (e.message.includes(' Incorrect username or password.')) {
      toast.error(e.message);
    } else if (e.message.includes('User does not exist')) {
      toast.error(e.message);
    } else {
      toast.error(
        <div>
          <div>Unexpected error. Please try again</div>
          {e.message ? <pre>{e.message}</pre> : null}
        </div>
      );
    }
    console.error(e);
    return;
  };

  const submitRenew = async (values, { setSubmitting }) => {
    console.log('Submitting renew password');
    const errorOut = async (e) => {
      if (e.message?.includes('User is not confirmed.')) {
        toast.error(
          'Please confirm your account with the e-mail that was sent to you.'
        );
      } else if (e.message?.includes(' Incorrect username or password.')) {
        toast.error(e.message);
      } else if (e.message?.includes('User does not exist.')) {
        toast.error(e.message);
      } else {
        toast.error(
          <div>
            <div>Unexpected Error. Please try again</div>
            {e?.message ? <pre>{e.message}</pre> : <pre>{e}</pre>}
          </div>
        );
      }
      console.error(e);
      return;
    };

    setSubmitting(true);
    try {
      const changePasswordResult = await authContext.forceChangePassword(
        cognitoUser,
        values.password,
        { given_name: values.given_name, family_name: values.family_name }
      );

      console.log('completed new password ');
      console.log(changePasswordResult);

      const loginResult = await authContext.logIn(
        cognitoUser.username,
        values.password
      );

      console.log('completed login ');
      console.log(loginResult);

      if (loginResult) {
        console.log('isAuth');
        setLoading(false);
        navigate('/app');
        return;
      } else {
        console.log('Renew password: is NOT Auth');
      }
    } catch (e) {
      console.log('Error on renew password', e);
      errorOut(e);
      setSubmitting(false);
      throw e;
    }
  };

  const validateRenew = (values) => {
    const errors = {};
    if (!values.currentPassword) {
      errors.currentPassword = 'Required';
    }
    if (!values.password) {
      errors.password = 'Required';
    }
    if (!values.passwordConfirm) {
      errors.passwordConfirm = 'Required';
    }
    if (!values.passwordConfirm === values.password) {
      errors.passwordConfirm = 'Passwords do not match';
    }

    if (!values.given_name) {
      errors.given_name = 'Required';
    }

    if (!values.family_name) {
      errors.family_name = 'Required';
    }

    return errors;
  };

  const submitLogin = async (values, { setSubmitting }) => {
    setSubmitting(true);
    // let awsUser;
    try {
      /* login in AWS */
      const loginResult = await authContext.logIn(
        values.username,
        values.password
      );

      setSubmitting(false);

      if (loginResult?.challengeName === 'NEW_PASSWORD_REQUIRED') {
        console.log('NEW_PASSWORD_REQUIRED');
        setCognitoUser(loginResult);
        setCurrentPassword(values.password);
        setMode(MODES.RENEW);
      }
    } catch (e) {
      errorOut(e);
      setSubmitting(false);
      return;
    }
  };

  const validateLogin = (values) => {
    const errors = {};
    if (!values.username) {
      errors.username = 'Obrigatório';
    }
    if (!isEmail(values.username)) {
      errors.username = 'E-mail inválido';
    }
    if (!values.password) {
      errors.password = 'Obrigatório';
    }
    return errors;
  };

  const LoginForm = () => (
    <Formik
      initialValues={{ username: '', password: '' }}
      validate={validateLogin}
      onSubmit={submitLogin}
    >
      {({ isSubmitting }) => (
        <Form className="w-full flex flex-col justify-start items-end">
          <div className="w-full flex flex-col">
            <div className="mb-2 flex flex-col justify-start items-start">
              <label
                className="flex justify-between w-full text-gray-700 mb-1"
                htmlFor="username"
              >
                E-mail
                <ErrorMessage
                  className="text-red-700 self-end"
                  name="username"
                  component="div"
                />
              </label>
              <Field
                type="text"
                name="username"
                className="appearance-none border border-gray-300 rounded-full w-full py-2 px-3 bg-gray-50 text-gray-700 leading-tight mb-3"
              />
            </div>

            <div className="mb-4 flex flex-col justify-start items-start">
              <label
                className="flex justify-between w-full text-gray-700 mb-1"
                htmlFor="password"
              >
                Password
                <ErrorMessage
                  className="text-red-700 self-end"
                  name="password"
                  component="div"
                />
              </label>
              <Field
                type="password"
                name="password"
                className="appearance-none border border-gray-300 rounded-full w-full py-2 px-3 bg-gray-50 text-gray-700 leading-tight mb-3"
              />
            </div>
            <div className="flex flex-col items-center">
              <button
                className="border border-blue-600 bg-blue-600 hover:border-blue-500 hover:bg-blue-500 text-white transition w-full py-2 px-4 rounded-full text-lg mb-4"
                type="submit"
                disabled={isSubmitting}
              >
                Log In
              </button>
              <button
                className="w-full flex justify-center text-lg text-gray-800 border border-transparent hover:border-gray-800 border-gray-800 hover:text-gray-800 py-2 px-4 rounded-full transition mb-4"
                disabled={isSubmitting}
              >
                <Link to="/recover">Lost password?</Link>
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );

  const RenewForm = () => {
    return (
      <Formik
        initialValues={{
          currentPassword: currentPassword ?? '',
          password: '',
          passwordConfirm: ''
        }}
        validate={validateRenew}
        onSubmit={submitRenew}
      >
        {({ isSubmitting }) => (
          <Form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex items-center">
            <div className="flex flex-col">
              <h1 className="block text-gray-500 text-2xl font-bold mb-8">
                Change Password
              </h1>
              <div className="mb-4 flex flex-col justify-start items-start">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="password"
                >
                  Current Password
                </label>
                <Field
                  type="password"
                  name="currentPassword"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-3"
                />
                <ErrorMessage
                  className="text-red-700 self-end"
                  name="currentPassword"
                  component="div"
                />
              </div>
              <div className="mb-4 flex flex-col justify-start items-start">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="password"
                >
                  New Password
                </label>
                <Field
                  type="password"
                  name="password"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-3"
                />
                <ErrorMessage
                  className="text-red-700 self-end"
                  name="password"
                  component="div"
                />
              </div>

              <div className="mb-6 flex flex-col justify-start items-start">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="passwordConfirm"
                >
                  Confirm New Password
                </label>
                <Field
                  type="password"
                  name="passwordConfirm"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-3"
                />
                <ErrorMessage
                  className="text-red-700 self-end"
                  name="passwordConfirm"
                  component="div"
                />
              </div>
              <div className="mb-2 flex flex-col justify-start items-start">
                <label
                  className="flex justify-between w-full text-gray-700 mb-1"
                  htmlFor="given_name"
                >
                  Primeiro Nome
                  <ErrorMessage
                    className="text-red-700 self-end"
                    name="given_name"
                    component="div"
                  />
                </label>
                <Field
                  type="text"
                  name="given_name"
                  className="appearance-none border border-gray-300 rounded-full w-full py-2 px-3 bg-gray-50 text-gray-700 leading-tight mb-3"
                />
              </div>

              <div className="mb-2 flex flex-col justify-start items-start">
                <label
                  className="flex justify-between w-full text-gray-700 mb-1"
                  htmlFor="family_name"
                >
                  Último Nome
                  <ErrorMessage
                    className="text-red-700 self-end"
                    name="family_name"
                    component="div"
                  />
                </label>
                <Field
                  type="text"
                  name="family_name"
                  className="appearance-none border border-gray-300 rounded-full w-full py-2 px-3 bg-gray-50 text-gray-700 leading-tight mb-3"
                />
              </div>
              <div className="flex items-center justify-between">
                <button
                  className="inline-block align-baseline p-4 rounded-md bg-gray-100 hover:bg-gray-200 font-light text-sm text-gray-800 hover:text-gray-600 mr-12"
                  disabled={isSubmitting || loading}
                  onClick={() => {
                    navigate('/login');
                  }}
                >
                  Back to Login
                </button>
                <button
                  type="submit"
                  className="inline-block align-baseline p-4 rounded-md font-bold text-sm  hover:bg-cyan-500 bg-sky-500 text-white"
                  disabled={isSubmitting || loading}
                >
                  Renew Password
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    );
  };

  return (
    <div
      className="flex justify-center items-center bg-blue-100 w-full h-full bg-fixed bg-center bg-no-repeat bg-cover"
      id="login"
    >
      <div className="flex flex-col items-center min-h-1/2 min-w-96 max-w-2/3 h-auto rounded-xl shadow-xl p-12 border bg-white border-gray-100 mb-4">
        <div
          className={`w-24 mb-12 max-w-full h-auto ${
            loading ? 'animate-pulse' : ''
          }`}
        >
          <Logo className="w-full h-auto"></Logo>
        </div>
        <div className={`mb-6 text-3xl h-auto`}>Login</div>
        {mode === MODES.LOGIN && <LoginForm></LoginForm>}
        {mode === MODES.RENEW && <RenewForm></RenewForm>}
      </div>
    </div>
  );
};

export default Login;
