import { backendAddress } from '../common/constants';
import { handleResponse, authenticate } from './common';

export async function getClient(nif) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  let response = {};
  try {
    response = await fetch(backendAddress + `client/${nif}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        jwt: userInfo?.signInUserSession.accessToken.jwtToken ?? ''
      }
    });
  } catch (err) {
    console.error(err);
    response = null;
  }

  return await handleResponse(response);
}

export async function getAllClients() {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  let response = {};
  try {
    response = await fetch(backendAddress + `client/all`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        jwt: userInfo?.signInUserSession.accessToken.jwtToken ?? ''
      }
    });
  } catch (err) {
    console.error(err);
    response = null;
  }

  return await handleResponse(response);
}

export async function postClient(values) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  let response = {};
  try {
    response = await fetch(backendAddress + `client`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        jwt: userInfo?.signInUserSession.accessToken.jwtToken ?? ''
      },
      body: JSON.stringify(values)
    });
  } catch (err) {
    console.error(err);
    response = null;
  }

  return await handleResponse(response);
}

export async function getTaxes(nif, datefrom = null, dateto = null) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  let address = backendAddress + 'tax/' + nif;

  if (datefrom) {
    address += '/' + datefrom;
  }
  if (dateto) {
    address += '/' + dateto;
  }

  const response = await fetch(address, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo?.signInUserSession.accessToken.jwtToken ?? ''
    }
  });
  return await handleResponse(response);
}

export async function getIvas(nif) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(backendAddress + 'iva/' + nif, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo?.signInUserSession.accessToken.jwtToken ?? ''
    }
  });
  return await handleResponse(response);
}

export async function putClient(nif, clientData) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(backendAddress + 'client/' + nif, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo?.signInUserSession.accessToken.jwtToken ?? ''
    },
    body: JSON.stringify(clientData)
  });
  return await handleResponse(response);
}

// export async function deleteUser(_id) {
//   const userInfo = await authenticate();
//   if (!userInfo) {
//     return null;
//   }

//   const response = await fetch(backendAddress + 'users/' + _id, {
//     method: 'DELETE',
//     headers: {
//       Accept: 'application/json',
//       'Content-Type': 'application/json',
//       jwt: userInfo?.signInUserSession.accessToken.jwtToken ?? ''
//     }
//   });
//   return await handleResponse(response);
// }

// export async function deleteUser(email) {
//   const userInfo = await authenticate();
//   if (!userInfo) {
//     return null;
//   }
//   const userData = {
//     username: email
//   };

//   const response = await fetch(backendAddress + 'user/delete', {
//     method: 'POST',
//     headers: {
//       Accept: 'application/json',
//       'Content-Type': 'application/json',
//       jwt: userInfo?.signInUserSession.accessToken.jwtToken ?? ''
//     },
//     body: JSON.stringify(userData)
//   });
//   return await handleResponse(response);
// }

// export async function postUser(userData) {
//   const userInfo = await authenticate();
//   if (!userInfo) {
//     return null;
//   }

//   // const userData = {
//   //   username:'fppimenta@hotmail.com',
//   //   password:'Aojwjvo76'
//   // };

//   const response = await fetch(backendAddress + 'user/create', {
//     method: 'POST',
//     headers: {
//       Accept: 'application/json',
//       'Content-Type': 'application/json',
//       jwt: userInfo?.signInUserSession.accessToken.jwtToken ?? ''
//     },
//     body: JSON.stringify(userData)
//   });
//   return await handleResponse(response);
// }

// export async function putUser(userData) {
//   const userInfo = await authenticate();
//   if (!userInfo) {
//     return null;
//   }

//   const response = await fetch(backendAddress + 'users/' + userData.cognitoID, {
//     method: 'PUT',
//     headers: {
//       Accept: 'application/json',
//       'Content-Type': 'application/json',
//       jwt: userInfo?.signInUserSession.accessToken.jwtToken ?? ''
//     },
//     body: JSON.stringify(userData)
//   });
//   return await handleResponse(response);
// }
