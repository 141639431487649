import React from 'react';
import { useNavigate } from 'react-router-dom';

import './Lost.scss';
const Lost = ({
  title,
  message = (
    <>
      <div>Nothing here.</div>
      <div className="border-b border-bluegray-500">Take me back.</div>
    </>
  ),
  className = ''
}) => {
  const navigate = useNavigate();
  return (
    <button
      className={`lost flex flex-col justify-center items-center w-1/2 h-auto text-gray-500 ${className}`}
      onClick={() => navigate(-1)}
    >
      {!!title && <h1 className="text-gray-500">Lost?</h1>}
      {!!message && (
        <div className="flex flex-col justify-center items-center text-gray-500">
          {message}
        </div>
      )}
    </button>
  );
};

export default Lost;
