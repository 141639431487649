import React from 'react';
import { useNavigate } from 'react-router-dom';
import isEmail from 'validator/lib/isEmail';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { toast } from 'react-toastify';

import AuthContext from '../../context/AuthContextBase';

import './Recover.scss';

const Recover = () => {
  const navigate = useNavigate();
  const appContext = React.useContext(AuthContext);
  const [secondStage, setSecondStage] = React.useState(false);
  let [username, setUsername] = React.useState('');

  const submitFirstStage = async (values, { setSubmitting }) => {
    setSubmitting(true);
    const user = values.username;
    setUsername(user); // save for second stage
    console.log({ user });
    try {
      await appContext.forgotPassword(user);
      setSubmitting(false);
      setSecondStage(true);
    } catch (error) {
      console.error(error);
      toast.error(error.message);
    }
    setSubmitting(false);
  };

  const submitSecondStage = async (values, { setSubmitting }) => {
    try {
      console.log('Submitting new password with code');
      const awsUser = await appContext.completeNewPassword(
        values.username,
        values.securityCode,
        values.password
      );
      console.log('Submitted: ', awsUser);
      const isAuth = await appContext.logIn(values.username, values.password);
      console.log(isAuth);
      if (isAuth) {
        navigate('/app');
      } else {
        navigate('/login');
      }
    } catch (e) {
      console.error(e);
      if (e?.code) {
        switch (e.code) {
          case 'ExpiredCodeException':
            toast.error('Code expired. Please request a new security code');
            setSecondStage(false);
            break;
          case 'InvalidPasswordException':
            // This no longer applies because the form does this validation. Leave this here anyway.
            toast.error(
              'Invalid Password. Passwords must contain lowercase and uppercase letters, and at least one number'
            );
            break;
          default:
            toast.error('Unexpected error. Please try again');
        }
      } else {
        toast.error('Unexpected error. Please try again');
      }
    }
  };

  return (
    <div
      id="recover"
      className="w-full h-full min-h-screen flex justify-center items-center flex-grow  bg-opacity-100"
    >
      <div className="w-full h-1/3 flex justify-center items-center">
        <div className="flex justify-start items-center h-auto">
          {!secondStage ? (
            <RecoverFirstStage
              {...{
                username,
                setUsername,
                onSubmit: submitFirstStage,
                skip: () => {
                  setSecondStage(true);
                }
              }}
            ></RecoverFirstStage>
          ) : (
            <RecoverSecondStage
              {...{ username, setUsername, onSubmit: submitSecondStage }}
            ></RecoverSecondStage>
          )}
        </div>
      </div>
    </div>
  );
};
export default Recover;

const RecoverFirstStage = ({ onSubmit = () => {}, skip = () => {} }) => {
  const navigate = useNavigate();

  const validate = (values) => {
    const errors = {};

    if (!values.username || !isEmail(values.username)) {
      errors.username = 'Required';
    }

    return errors;
  };

  return (
    <Formik
      initialValues={{ username: '', password: '' }}
      validate={validate}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => (
        <Form className="recover-form w-full h-full shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col justify-start items-center">
          <div className="w-full flex flex-col justify-center items-start">
            <div className="mb-4 w-full flex flex-col justify-start items-start">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="username"
              >
                E-mail
              </label>
              <Field
                type="email"
                name="username"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-3"
              />
              <ErrorMessage
                className="text-red-700 self-end"
                name="username"
                component="div"
              />
            </div>

            <div className="flex justify-between items-center w-full mb-6">
              <button
                disabled={isSubmitting}
                type="submit"
                className="w-full bg-sky-600 hover:scale-105 transform transition text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline "
              >
                Send
              </button>
            </div>
            <div className="flex justify-between items-center w-full mb-4">
              <button
                className="border border-sky-600 hover:scale-105 transform transition font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mr-8"
                disabled={isSubmitting}
                onClick={skip}
              >
                I already have a code
              </button>
              <button
                className="border border-sky-600 hover:scale-105 transform transition font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                onClick={() => {
                  navigate('/login');
                }}
              >
                Go Back
              </button>
            </div>

            {/* <div className="flex justify-end w-full">
              <Link
                to="/renew"
                className="py-2 rounded underline"
                type={'submit'}
                onClick={async () => {}}
              >
                <span>First Sign-in? Confirm account</span>
              </Link>
            </div>
            <div className="flex justify-end items-start w-full mb-3"></div> */}
          </div>
        </Form>
      )}
    </Formik>
  );
};

const RecoverSecondStage = ({
  onSubmit = () => {},
  cancel = () => {},
  username
}) => {
  const navigate = useNavigate();

  const validate = (values) => {
    const errors = {};

    if (!values.username || !isEmail(values.username)) {
      errors.username = 'Required';
    }

    if (!values.securityCode) {
      errors.securityCode = 'Required (please check your your e-mail)';
    }

    const password = values.password;
    const hasNumber = /\d/.test(password);
    const hasLowercase = /[a-z]/.test(password);
    const hasUppercase = /[A-Z]/.test(password);
    const hasWhitespace = /\s/.test(password);

    if (hasWhitespace) {
      errors.password = 'Space characters not allowed';
    }

    if (!password || password?.length < 8) {
      errors.password = 'Minimum 8 characters';
    }

    if (!hasNumber) {
      errors.password = 'Must contain a number';
    }

    if (!hasUppercase) {
      errors.password = 'Must contain a lowercase character';
    }

    if (!hasLowercase) {
      errors.password = 'Must contain an uppercase character';
    }

    return errors;
  };
  return (
    <Formik
      initialValues={{ username, password: '' }}
      validate={validate}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => (
        <Form className="recover-form min-w-96 w-auto h-full bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col justify-start items-center">
          <div className="flex flex-col justify-center items-start w-full mb-3">
            <div className="mb-4 w-full flex flex-col justify-start items-start">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="username"
              >
                E-mail
              </label>
              <Field
                type="email"
                name="username"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-3"
              />
              <ErrorMessage
                className="text-red-700 self-end"
                name="username"
                component="div"
              />
            </div>

            <div className="mb-4 w-full flex flex-col justify-start items-start">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="securityCode"
              >
                Security Code
              </label>
              <Field
                name="securityCode"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-3"
              />
              <ErrorMessage
                className="text-red-700 self-end"
                name="securityCode"
                component="div"
              />
            </div>

            <div className="mb-6 w-full flex flex-col justify-start items-start">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="password"
              >
                Password
              </label>
              <Field
                type="password"
                name="password"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-3"
              />
              <ErrorMessage
                className="text-red-700 self-end"
                name="password"
                component="div"
              />
            </div>

            <div className="flex flex-col w-full mb-6">
              <button
                disabled={isSubmitting}
                className="w-full bg-sky-600 hover:scale-105 transform transition text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mb-6"
                type="submit"
              >
                Reset Password
              </button>
              <button
                className="border border-sky-600 hover:scale-105 transform transition font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                onClick={() => {
                  navigate('/login');
                }}
              >
                Go Back
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

// eslint-disable-next-line no-unused-vars
const errorOut = async (e) => {
  if (e.message.includes('User is not confirmed.')) {
    toast.error('Please confirm your account with the email we sent you');
  } else if (e.message.includes(' Incorrect username or password.')) {
    toast.error(e.message);
  } else if (e.message.includes('User does not exist')) {
    toast.error(e.message);
  } else {
    toast.error(
      <div>
        <div>Unexpected error. Please try again</div>
        {e.message ? <pre>{e.message}</pre> : null}
      </div>
    );
  }
  console.error(e);
  return;
};
