import { CalendarIcon } from '@heroicons/react/24/solid';
import { toast } from 'react-toastify';
import merge from 'lodash/merge';
import moment from 'moment';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import AppContext from '../../context/AppContextBase';
import AuthContext from '../../context/AuthContextBase';
import { getTaxes, postClient, putClient } from '../../network/clients';
import { CheckIcon, PlusCircleIcon } from '@heroicons/react/24/outline';
import { ErrorMessage, Field, Form, Formik } from 'formik';

const AdminClients = () => {
  const authContext = React.useContext(AuthContext);
  const appContext = React.useContext(AppContext);
  const { userBE } = authContext ?? {};
  const clients = appContext.clients;

  const [editClient, setEditClient] = React.useState(null);
  const [createClient, setCreateClient] = React.useState(null);

  const editValue = (value, field) => {
    setEditClient((prevClient) => ({
      ...prevClient,
      [field]: value
    }));
  };

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const header = (
    <div className="w-full h-auto flex flex-col whitespace-nowrap pb-12">
      <div className="font-medium text-2xl text-gray-600 pb-1">
        {userBE?.name}
      </div>
      <div className="flex text-gray-600 whitespace-nowrap">
        <CalendarIcon className="w-6 h-6 self-center mr-1"></CalendarIcon>
        <div className=" text-xl text-gray-400 ">{moment().format('LL')}</div>
      </div>
    </div>
  );

  const saveEdits = async () => {
    const response = await putClient(editClient.nif, editClient);

    if (!response.ok || !response.data) {
      toast.error('Error saving data. please try again later');
      console.error('Error saving data, response: ', response);
      return;
    }

    const clientFromBE = response.data;

    const targetClient = clients.find((c) => c.nif === editClient?.nif);

    merge(targetClient, clientFromBE);
    setEditClient(null);
    toast.success('Saved data');
  };

  return (
    <div className="w-full min-h-full pb-8 flex flex-col">
      {header}
      <div className="relative w-full flex items-center justify-between pb-4">
        <div className="text-4xl font-semibold">Clients</div>
        <div className="flex items-center  rounded-md">
          {process.env.NODE_ENV === 'development' && (
            <button
              onClick={async () => {
                const response = await getTaxes('500409838', '2022-11-16', '2022-11-17');
                console.log(response)
              }}
              className="flex items-center mr-2  cursor-pointer hover:bg-gray-300 bg-gray-200 rounded-md px-2 py-1"
            >
              <PlusCircleIcon className="w-8 h-8 stroke-1 stroke-current pr-2"></PlusCircleIcon>
              Test date routes
            </button>
          )}
          {!createClient ? (
            <button
              onClick={() => {
                setCreateClient(true);
              }}
              className="flex items-center cursor-pointer hover:bg-gray-300 bg-gray-200 rounded-md px-2 py-1"
            >
              <PlusCircleIcon className="w-8 h-8 stroke-1 stroke-current pr-2"></PlusCircleIcon>
              Create Client
            </button>
          ) : (
            <CreateClientForm
              onSave={() => {
                setCreateClient(false);
                appContext.getClients();
              }}
            ></CreateClientForm>
          )}
        </div>
      </div>
      <div className="w-full flex-1 flex flex-col border border-gray-200">
        <div className={`w-full flex items-center border-b border-gray-100`}>
          <div className="flex items-center w-8 h-full text-xl p-4">N</div>
          <div className="flex items-center w-1/6 h-full text-xl p-4">NIF</div>
          <div className="flex items-center w-1/6 h-full text-xl p-4">
            Password
          </div>
          <div className="flex items-center w-1/6 h-full text-xl p-4">Name</div>
          <div className="flex items-center flex-1 w-1/6 h-full text-xl p-4">
            Contact
          </div>
          <div className="flex items-center flex-1 w-1/6 h-full text-xl p-4">
            Notes
          </div>
          <div className="flex items-center justify-end w-1/12 h-full text-xl p-4">
            Actions
          </div>
        </div>
        {clients?.map((client, index) => {
          const isEven = index % 2 === 0;
          const isEditing = editClient?.nif === client?.nif;
          return (
            <div
              key={index}
              className={`relative w-full font-normal flex items-center border-b border-gray-100 hover:bg-blue-50 ${
                isEven ? 'bg-white' : 'bg-gray-50'
              }`}
            >
              <div className="w-8 p-4 h-full">{index}</div>
              <div className="flex items-center w-1/6 h-full text-xl p-4">
                {client?.nif ?? '?'}
              </div>
              <div className="relative flex items-center w-1/6 h-full text-xl p-4">
                <EditableInput
                  type={isEditing ? 'text' : 'password'}
                  client={client}
                  field="password"
                  editClient={editClient}
                  editValue={editValue}
                ></EditableInput>
              </div>
              <div className="flex items-center w-1/6 h-full text-xl p-4">
                <EditableInput
                  client={client}
                  field="name"
                  editClient={editClient}
                  editValue={editValue}
                ></EditableInput>
              </div>
              <div className="flex items-center flex-1 w-1/6 h-full text-xl p-4">
                <EditableInput
                  client={client}
                  field="contact"
                  editClient={editClient}
                  editValue={editValue}
                ></EditableInput>
              </div>
              <div className="flex items-center flex-1 w-1/6 h-full text-xl p-4">
                <EditableInput
                  client={client}
                  field="description"
                  editClient={editClient}
                  editValue={editValue}
                ></EditableInput>
              </div>
              <div className="flex flex-col  items-center justify-end w-1/12 h-full text-md p-2">
                {!isEditing &&
                  (client?.nif ? (
                    <>
                      <Link
                        to={`/app/client/${client?.nif}`}
                        className="text-lg select-none text-white text-center w-full mb-2 px-1 rounded-xl bg-blue-500 hover:bg-blue-400 transition"
                      >
                        View
                      </Link>
                      <button
                        onClick={() => {
                          setEditClient(client);
                        }}
                        className="text-lg select-none text-white text-center w-full px-1 rounded-xl bg-blue-500 hover:bg-blue-400 transition"
                      >
                        Edit
                      </button>
                    </>
                  ) : (
                    <>
                      <div
                        title="Missing NIF"
                        className="text-lg cursor-not-allowed select-none text-white text-center w-full mb-2 px-1 rounded-xl bg-gray-400 hover:bg-gray-300 transition"
                      >
                        View
                      </div>
                      <div
                        title="Missing NIF"
                        className="text-lg cursor-not-allowed select-none text-white text-center w-full px-1 rounded-xl bg-gray-400 hover:bg-gray-300 transition"
                      >
                        Edit
                      </div>
                    </>
                  ))}

                {isEditing ? (
                  <>
                    <button
                      onClick={() => setEditClient(null)}
                      className="text-lg select-none text-white text-center w-full mb-2 px-1 rounded-xl bg-red-500 hover:bg-red-400 transition"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={saveEdits}
                      className="text-lg select-none text-white text-center w-full px-1 rounded-xl bg-green-500 hover:bg-green-400 transition"
                    >
                      Save
                    </button>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AdminClients;

const EditableInput = ({
  client,
  editClient,
  editValue,
  field,
  className = '',
  ...restProps
}) => {
  if (!client)
    return (
      <input
        disabled={true}
        className={`w-full px-2 h-10 rounded-md border bg-gray-50 border-gray-200 ${className}`}
        value={'?'}
      ></input>
    );
  const isEditing = editClient?.nif === client?.nif;
  return (
    <input
      key={`input-${client.nif}-${field}`}
      {...restProps}
      disabled={!isEditing}
      className={`w-full px-2 h-10 rounded-md border ${
        isEditing ? 'bg-gray-100 border-gray-400' : 'bg-gray-50 border-gray-200'
      }  ${className}`}
      value={isEditing ? editClient?.[field] : client?.[field]}
      onChange={(e) => {
        editValue(e.target.value, field);
      }}
    ></input>
  );
};

const clientTemplate = {
  name: '',
  contact: '',
  description: '',
  nif: '',
  password: ''
};
const CreateClientForm = ({ onSave }) => {
  const validate = (values) => {
    const errors = {};

    if (!(values?.name?.length > 1)) {
      errors.name = 'Min. 2 characters';
    }

    if (!(values?.nif?.length > 1)) {
      errors.nif = 'Mandatory';
    }

    if (!(values?.password?.length > 1)) {
      errors.password = 'Mandatory';
    }

    return errors;
  };

  const onSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);

    const response = await postClient(values);
    if (response?.ok) {
      toast.success('Created user successfully');

      return await onSave(response.data);
    } else {
      console.error(response);
      toast.error(
        'Error creating user: ' +
          (response.message ?? 'Unknown. Please try again')
      );
    }

    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={clientTemplate}
      validate={validate}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => (
        <Form className="flex items-center bg-gray-200 rounded-md px-4">
          <div className="min-w-36 pr-2 flex flex-col justify-start items-start">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="name"
            >
              <span className="pr-4">Name</span>
              <ErrorMessage
                className="text-red-700 font-normal"
                name="name"
                component="div"
              />
            </label>
            <Field
              name="name"
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-3"
            />
          </div>

          <div className="min-w-36 pr-2 flex flex-col justify-start items-start">
            <label
              className="flex items-start text-gray-700 text-sm font-bold mb-2"
              htmlFor="nif"
            >
              <span className="pr-4">NIF</span>
              <ErrorMessage
                className="text-red-700"
                name="nif"
                component="div"
              />
            </label>
            <Field
              name="nif"
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-3"
            />
          </div>

          <div className="min-w-36 pr-2 flex flex-col justify-start items-start">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="password"
            >
              <span className="pr-4">Password</span>
              <ErrorMessage
                className="text-red-700"
                name="password"
                component="div"
              />
            </label>
            <Field
              type="password"
              name="password"
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-3"
            />
          </div>
          <div className="min-w-36 pr-2 flex flex-col justify-start items-start">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="contact"
            >
              <span className="pr-4">E-mail</span>
              <ErrorMessage
                className="text-red-700"
                name="contact"
                component="div"
              />
            </label>
            <Field
              type="email"
              name="contact"
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-3"
            />
          </div>
          <div className="min-w-36 pr-2 flex flex-col justify-start items-start">
            <label
              className="flex items-start text-gray-700 text-sm font-bold mb-2"
              htmlFor="description"
            >
              <span className="pr-4">Description</span>
              <ErrorMessage
                className="text-red-700"
                name="description"
                component="div"
              />
            </label>
            <Field
              name="description"
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-3"
            />
          </div>

          <div className="pt-2 flex flex-col justify-start items-start">
            <label className="flex items-start text-gray-700 text-sm font-bold mb-2">
              {' '}
            </label>
            <button
              className="h-full flex items-center bg-blue-400 hover:bg-blue-500 bg-complear-dark text-white py-2 px-4 rounded focus:outline-none"
              type="submit"
              disabled={isSubmitting}
            >
              <CheckIcon className="w-6 h-6"></CheckIcon>
              <span className="pl-4 whitespace-nowrap">Create</span>
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
