import React, { useState, useContext } from 'react';
import { toast } from 'react-toastify';
import { Formik, Form, Field, ErrorMessage } from 'formik';

import AuthContext from '../../context/AuthContextBase';

const ChangePasswordForm = ({ onSuccess = () => {}, onFailure = () => {} }) => {
  const authContext = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const onSubmit = async (values, { setSubmitting }) => {
    const errorOut = async (e) => {
      if (e.message.includes('User is not confirmed.')) {
        toast.error(
          'Please confirm your account with the e-mail that was sent to you.'
        );
      } else if (e.message.includes(' Incorrect username or password.')) {
        toast.error(e.message);
      } else if (e.message.includes('User does not exist.')) {
        toast.error(e.message);
      } else {
        toast.error(
          <div>
            <div>Unexpected Error. Please try again</div>
            {e.message ? <pre>{e.message}</pre> : null}
          </div>
        );
      }
      console.error(e);
      return;
    };

    setSubmitting(true);
    try {
      const result = await authContext.changePassword(
        values.currentPassword,
        values.password
      );
      console.log('completed new password ');
      setLoading(false);
      return await onSuccess(result);
    } catch (e) {
      errorOut(e);
      setSubmitting(false);
      return await onFailure(e.message);
    }
  };

  const validate = (values) => {
    const errors = {};

    if (!values.currentPassword) {
      errors.currentPassword = 'Required';
    }

    if (!values.password) {
      errors.password = 'Required';
    }

    if (!values.passwordConfirm) {
      errors.passwordConfirm = 'Required';
    }

    if (!(values.passwordConfirm === values.password)) {
      errors.passwordConfirm = 'Passwords do not match';
    }

    return errors;
  };

  return (
    <div className="w-full h-auto flex pb-4">
      <Formik
        initialValues={{
          password: '',
          passwordConfirm: '',
          currentPassword: ''
        }}
        validate={validate}
        onSubmit={onSubmit}
      >
        {({ isSubmitting }) => (
          <Form className="pt-6 flex items-center">
            <div className="flex flex-col">
              <h1 className="block text-gray-500 text-lg font-bold mb-4">
                Change Password
              </h1>
              <div className="mb-4 text-xs text-gray-700">Passwords must have at least 8 characters, one lowercase and one uppercase character, and at least one number.</div>
              <div className="mb-4 flex flex-col justify-start items-start">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="currentPassword"
                >
                  Current Password
                </label>
                <Field
                  type="password"
                  name="currentPassword"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-3"
                />
                <ErrorMessage
                  className="text-red-700 text-xs"
                  name="currentPassword"
                  component="div"
                />
              </div>
              <div className="mb-4 flex flex-col justify-start items-start">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="password"
                >
                  New Password
                </label>
                <Field
                  type="password"
                  name="password"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-3"
                />
                <ErrorMessage
                  className="text-red-700 text-xs"
                  name="password"
                  component="div"
                />
              </div>

              <div className="mb-6 flex flex-col justify-start items-start">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="passwordConfirm"
                >
                  Confirm New Password
                </label>
                <Field
                  type="password"
                  name="passwordConfirm"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-3"
                />
                <ErrorMessage
                  className="text-red-700 text-xs"
                  name="passwordConfirm"
                  component="div"
                />
              </div>
              <div className="flex items-center justify-between">
                <button
                  type="submit"
                  className="inline-block align-baseline px-4 py-2 rounded-md font-bold text-sm border border-gray-800 hover:border-orange-600 hover:bg-orange-600 bg-white text-gray-800 hover:text-white"
                  disabled={isSubmitting || loading}
                >
                  Alterar Password
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ChangePasswordForm;
